<template>
    <div id="MarketingTools">
        <div class="MarketingTools_list">
            <div class="MarketingTools_list_item" v-for="item in list" :key="item.id" @click="toPage(item.name)">
                <div class="left">
                    <img src="https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/17/sass_coupon" alt="">
                </div>
                <div>
                    <div class="right_title">
                        {{ item.name }}
                        <img src="https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202404/17/sass_share">
                    </div>
                    <div class="right_desc">
                        {{ item.desc }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    id: 1,
                    name: '优惠券',
                    desc: '营销费用灵活掌控，社群发券有效轻松'
                }, {
                    id: 2,
                    name: '分享推广',
                    desc: '用户裂变，领取佣金'
                },
            ]
        }
    },
    created() {

    },
    mounted() { },
    methods: {
        // 跳转页面
        toPage(name) {
            if (name == '优惠券') {
                this.$router.push({ path: '/EsportsMember/MarketingTools_coupon' })
            } else if (name == '分享推广') {
                this.$router.push({ path: '/EsportsMember/MarketingTools_SharePromotion' })
            }
        }
    }
}

</script>

<style lang="scss" scoped>
#MarketingTools {
    .MarketingTools_list {
        display: flex;
        flex-wrap: wrap;

        .MarketingTools_list_item {
            width: 258px;
            height: 74px;
            background-color: #F8F7F7;
            display: flex;
            align-items: center;
            margin: 0 20px 20px 0;
            padding-left: 20px;

            .left {
                img {
                    width: 32px;
                    height: 32px;
                }

                margin-right: 10px;
            }

            .right_title {
                font-size: 14px;
                margin-bottom: 6px;
                display: flex;
                align-items: center;

                img {
                    margin-left: 10px;
                    width: 12px;
                    height: 12px;
                }
            }

            .right_desc {
                color: #909399;
                font-size: 11px;
            }
        }
    }

}
</style>